import React, { useState, useEffect } from 'react'
import Image from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

const Team = ({ page }) => {
  const pagePosts = useStaticQuery(graphql`
    query BrandTeamPosts {
      allWpCptBrandTeam {
        edges {
          node {
            title
            content
            featuredImage {
              node {
                altText
                description
                title
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1000, quality: 60) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            acfbrandpageteam {
							gifBrandPageTeam {
								localFile {
									publicURL
                  name
                  base
                  ext
                }
              }
            }
          }
        }
      }
    }
  `)

  const sections = pagePosts.allWpCptBrandTeam.edges

  /*    mouse over / touch start ACTION   */
  const[itemOver, setItemOver] = useState(false)

  function overX(i) {
    setItemOver(i)
  }
  function cleanX() {
    setItemOver(false)
  }

  return (
    <div className="grid">
      <div className="column column--left">
        {/* <h1 className={`${page.title.length > 8 ? 'heading--long' : ''} mobileFixed`}> */}
        <h1 className={`${page.title.length > 6 ? 'heading--long' : ''}`}>
          {page.title}
        </h1>
        <div className={`all-sections`}>
          {sections.length > 0 && sections.map((section, key) => {
            return(
              <section 
                key={key}
                className={`section sectionTeamBrand`}
                onMouseEnter={() => overX(key)}
                onMouseLeave={() => cleanX()}
                onTouchStart={() => overX(key)}
                onTouchEnd={() => cleanX()}
                role="button"
                tabIndex="0"
              >
                <h2>{section.node.title}</h2>
                <div
                  className={`gifImgWrap`} 
                  style={{maxWidth:'600px',height:'auto'}}>

                  {/* {itemOver === key && <img 
                    src={section.node.acfbrandpageteam.gifBrandPageTeam.localFile.publicURL} 
                    alt={``}
                    className={`gif`}
                    style={itemOver === key ? {opacity: '1'} : {opacity: '0'}}
                  ></img>} */}

                  <Image
                    fluid={section.node.featuredImage.node.localFile.childImageSharp.fluid}
                    alt={section.node.featuredImage.node.altText}
                    title={section.node.featuredImage.node.title}
                    description={section.node.featuredImage.node.description}
                    className={`gifThumb`}
                    style={itemOver === key ? {opacity: '1'} : {opacity: '1'}}
                  />

                </div>

                <div className={`wpContent`} dangerouslySetInnerHTML={{ __html: section.node.content }}></div>
              </section>
            )
          })}
        </div>

      </div>

    </div>
  )
}
export default Team
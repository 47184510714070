import React, { useEffect } from 'react'
import { graphql } from 'gatsby'

import BrandLayout from 'src/components/layouts/brandLayout'
import Seo from 'src/components/seoWp'

import Start from 'src/components/brand/pages/start'
import Idea from 'src/components/brand/pages/idea'
import Team from 'src/components/brand/pages/team'
import Technology from 'src/components/brand/pages/technology'
import Offer from 'src/components/brand/pages/offer'
import Products from 'src/components/brand/pages/products'
import Career from 'src/components/brand/pages/career'
import Contact from 'src/components/brand/pages/contact'
import ThankYou from 'src/components/brand/pages/thankYou'
import Default from 'src/components/brand/pages/default'

// import {
//   GlobalStateContext,
//   GlobalDispatchContext,
// } from 'src/context/GlobalContextProvider'

// import 'src/styles/global.scss'
// import 'src/styles/swiper.scss'
import 'src/styles/brand.scss'


const BrandPageTemplate = ({ data: { page, subpage }, pageContext, location }) => {
  
  // const state = useContext(GlobalStateContext)
  // const dispatch = useContext(GlobalDispatchContext)


  // const currentSectionUpdate = () => {
  //   dispatch({ type: 'CURRENT_SECTION', value: pageContext.siteSlug })
  // }
  const sessionStorageUpdate = () => {
    window.localStorage.setItem("loco", pageContext.siteSlug)
  }
  useEffect(() => {
    // state.currentSection !== pageContext.siteSlug &&
    window.localStorage.getItem("loco") !== pageContext.siteSlug &&
    // currentSectionUpdate()
    sessionStorageUpdate()
  }, [])  // eslint-disable-line react-hooks/exhaustive-deps


  page.subpage = pageContext.subpage
  page.subpage2lev = pageContext.subpage2lev

  const contentMap = {
    start: Start,
    idea: Idea,
    team: Team,
    technology: Technology,
    offer: Offer,
    'products': Products,
    career: Career,
    contact: Contact,
    'thank-you': ThankYou,
  }
  const Content = page.AcfGatsbyId.pageid in contentMap ? contentMap[page.AcfGatsbyId.pageid] : Default

  return (
    <React.Fragment>
      <BrandLayout pageContext={pageContext} page={page}>
        <Seo 
          seo={(typeof subpage !== 'undefined' && subpage !== null) ? subpage.seo : page.seo} 
          site={pageContext.siteSlug} 
          // slug={page.slug} 
          slug={(typeof subpage !== 'undefined' && subpage !== null) ? subpage.slug : page.slug}
          gatsbyId={page.AcfGatsbyId.pageid} 
          subpageid={typeof pageContext.subpage !== 'undefined' && pageContext.subpage.AcfGatsbyId.pageid} 
          siteSlug={pageContext.realSlugs[pageContext.siteSlug]}
        />
        <Content pageContext={pageContext} page={page} subpage={subpage} location={location} />
      </BrandLayout>
    </React.Fragment>
  )
}

export default BrandPageTemplate

export const pageQuery = graphql`
  query BrandPageById($id: String!, $subpageId: String!) {
    page: wpCptBrandPage(id: { eq: $id }) {
      id
      slug
      title
      content
      AcfGatsbyId {
        pageid
      }
      acffreshmailsubs {
				freshmailSubscriptionCheckbox
        freshmailSubscriptionsListId
        checkboxDescriptionForUsers
      }
      seo {
        focuskw
        title
        canonical
        opengraphUrl
        metaDesc
        schema {
          raw
        }
      }
      featuredImage {
        node {
          altText
          description
          title
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 60) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }

      wpChildren {
        nodes {
          ... on WpCPTBrandPage {
            id
            slug
            title
            content
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 440, quality: 60) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            cPTTXjobOfferTypess {
              nodes {
                slug
              }
            }
            acffreshmailsubs {
              freshmailSubscriptionCheckbox
              freshmailSubscriptionsListId
              checkboxDescriptionForUsers
            }
          }
        }
      }
    }

    subpage: wpCptBrandPage(id: { eq: $subpageId }) {
      id
      slug
      title
      content
      acffreshmailsubs {
        freshmailSubscriptionCheckbox
        freshmailSubscriptionsListId
        checkboxDescriptionForUsers
      }
      seo {
        focuskw
        title
        canonical
        opengraphUrl
        metaDesc
      }
      featuredImage {
        node {
          altText
          description
          title
          localFile {
            childImageSharp {
              fluid(maxWidth: 950, quality: 60) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      acfbrandpageoffer {
        subheaderBrandPageOffer
        ctaLabelBrandPageOffer
        ctaLinkBrandPageOffer
      }
    }
  }
`

import React, { useContext, useLayoutEffect, useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import SvgPlay from 'src/images/playButton'

import {
  GlobalStateContext,
  GlobalDispatchContext,
} from 'src/context/GlobalContextProvider'

const Start = ({ page }) => {
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)
  const graphqlResult = useStaticQuery(graphql`
    query BrandStartPage {
      start: wpCptBrandPage(AcfGatsbyId: {pageid: {eq: "start"}}) {      
        acfbrandpagestart {
          subheader2BrandPageStart
          subheaderBrandPageStart
          videoIframeBrandPageStart
          videoLoopBrandPageStart {
            localFile {
							publicURL
            }
          }
        }
      }
    }
  `)

  /*    VIDEO HANDLLING   */
  function playVideo() {
    dispatch({ type: 'PLAY_START_BRAND' })
  }
  const hasPlayer = playVideo
  const hasPlayerStop = state.playStartBrand ? playVideo : undefined
  /*    END VIDEO HANDLLING   */

  let containerClass = state.playStartBrand ? "videoPlaying" : "videoStoped"
  // const featuredImage = {
  //   fluid: page.featuredImage?.node?.localFile?.childImageSharp?.fluid,
  //   alt: page.featuredImage?.node?.alt || ``,
  // }
  

  
  /*    VISUAL EFECT FOR VIDEO SHOW    */
  const videoClassTrue = () => {
    document.body.classList.add('startVideoShowStatic')
    document.body.classList.add('startVideoShow')
  }
  const videoClassFalse = () => {
    document.body.classList.remove('startVideoShowStatic')
    document.body.classList.add('startVideoHide')  }
  useLayoutEffect(() => {  
    state.playStartBrand ?    
    setTimeout(() => {
      videoClassTrue()
    }, 333) 
    :
    setTimeout(() => {
      videoClassFalse()
    }, 333)     
    const timer = 
    state.playStartBrand ?
    setTimeout(() => {
      document.body.classList.remove('startVideoShow')
    }, 4000) 
    :
    setTimeout(() => {
      document.body.classList.remove('startVideoHide')
    }, 4000) 
    return () => clearTimeout(timer)
  }, [state.playStartBrand]);


  const [, forceUpdate] = useState();
  useEffect(() => {
    const timer =
    setTimeout(forceUpdate, 666);
    // console.log('FORCE UPDATE')
    return () => clearTimeout(timer)
  }, []);
  


  return (
    <>
      {state.playStartBrand && <>
        <div 
          className={`videoContainer ${containerClass}`}
        >
          <div className={`videoContainerInner`}>
              <>
                <iframe title="modernforms promo" 
                  width={`${state.windowWidth < 1280 ? state.windowWidth - 24 : 1280}`} 
                  height={`${state.windowWidth < 1280 ? state.windowWidth * 0.5625 - 24: 720}`} 
                  src={graphqlResult.start.acfbrandpagestart.videoIframeBrandPageStart}
                  frameBorder="0" 
                  allowFullScreen
                  allow="
                    accelerometer; 
                    autoplay; 
                    clipboard-write; 
                    encrypted-media; 
                    gyroscope; 
                    picture-in-picture
                  "
                >This browser does not support iframe video</iframe>
              </>
          </div> 
        </div>
        <div className={`videoControl`} onClick={hasPlayerStop} role="button" tabIndex="0" onKeyDown={hasPlayerStop} aria-label="forward button" style={state.playStartBrand ? {
          background: 'radial-gradient(ellipse at 35%,rgb(38, 38, 43), rgb(14, 14, 18))'
        } : {}}>
            <span
              className={`closeVideo`}
              >
              <FontAwesomeIcon icon={faTimes}/>
            </span>
          </div>
          </>
            }            

      <div className="grid">
        <div className={`column column--left ${state.playStartBrand === true ? 'videoPlay' : ''}`}
          style={{

            // marginTop: `${(state.windowWidth > 992) ? 10 : state.windowWidth > 600 ? state.windowWidth * 0.5625 - 120 : state.windowWidth * 0.5625 - 48}px`,

            position:'relative',
            // top: `${(state.windowWidth > 992) ? 10 : state.windowWidth > 600 ? state.windowWidth * 0.5625 - 120 : state.windowWidth * 0.5625 - 48}px`,
            marginTop: `${(state.windowWidth > 992) ? 43 : state.windowWidth > 600 ? state.windowWidth * 0.5625 - 120 : state.windowWidth > 520 ? state.windowWidth * 0.5625 - 48 : 260}px`,

          }}
        >
          <h1 className={page.title.length > 6 ? 'heading--long' : ''}>
            {page.title}
          </h1>
          <h2>{graphqlResult.start.acfbrandpagestart.subheaderBrandPageStart}</h2>
          <span className={`beforeImgTxt`}>{graphqlResult.start.acfbrandpagestart.subheader2BrandPageStart}</span>
          <div className={`wpContent`} dangerouslySetInnerHTML={{ __html: page.content }}></div>
        </div>

        {!state.playStartBrand && 
        <>
        <div 
          className={`videoLoop ${containerClass}`}
          style={
            state.windowWidth > 992 ?
            {
              // left: `${49}px`,
              // left: `${32}px`,

              // left: `${100}px`,
              // left: 'calc(((100vw - 100%) / 2) * -1)',
              left: `calc(((100vw - (${state.windowWidth - 200}px)) / 2))`,

              // top: `calc(((100vh - (${state.windowWidth * 0.5625 - 220}px)) / 2))`,
              top: `133px`,
              // marginBottom: '60px',
            } :
            state.windowWidth > 600 ?
            {
              left: `0px`,
              top: `131px`,
            } :
            {
              // left: `0px`,
              left: `calc((100% - 600px) / 2)`,
              top: `160px`,
            }
          }
        >
          <div
            style={{
              width:`${(state.windowWidth > 992) ? state.windowWidth - 200 : state.windowWidth > 600 ? state.windowWidth : 600}px`,

              // height: `${state.windowWidth < 1580 ? `auto` : `748px`}`,
              height: `${state.windowWidth < 1580 ? `auto` : `${state.windowHeight - 199}px`}`,
              // height: `${`auto`}`,

              display: 'flex',
              justifyContent: 'center',
              // maxWidth: '100%'
            }}
          >
          <video 
            // height='auto' 
            // height={`${(state.windowWidth > 992) ? (state.windowWidth - 200) * 0.4625 : state.windowWidth > 600 ? state.windowWidth * 0.4625 : 600 * 0.4625}px`}  
            // width={`${(state.windowWidth > 992) ? state.windowWidth - 98 : state.windowWidth}`} 

            // width={`${(state.windowWidth > 992) ? state.windowWidth - 64 : state.windowWidth}`} 

            // width={`${(state.windowWidth > 992) ? state.windowWidth - 200 : state.windowWidth}`} 

            // width={`${(state.windowWidth > 992) ? state.windowWidth - 200 : state.windowWidth > 600 ? state.windowWidth : 600}px`} 
            width={`100%`}
            
            // height={(state.windowHeight - 400)}

            // height={state.windowWidth < 1580 ? `auto` : `748px`}
            height={`100%`}
            style={{ objectFit: `cover` }}

            autoPlay 
            loop 
            muted
            playsInline
          >
            <source src={graphqlResult.start.acfbrandpagestart.videoLoopBrandPageStart.localFile.publicURL} type="video/mp4" />
          </video>
          </div>
        </div>

        {/* <div 
          className={`desktopHolder`} 
          style={state.playStartBrand ? {} : {minHeight:`${state.windowWidth * 0.5625 - 300}px`}}
        >          
        </div> */}

        </>
      }
        
          {/* {!state.playStartBrand &&
            <div 
            className={`playHandler ${state.playStartBrand === true ? 'videoPlay' : ''}`}
            style={
              state.windowWidth > 992 ?
              {
                // top: `calc(((100vh - (${state.windowWidth * 0.5625 / 2 - 220}px)) / 2))`,
                // top: `calc(${state.windowWidth * 0.5625 / 2 - 125}px)`,

                top: `calc(${state.windowWidth < 1580 ? (state.windowWidth * 0.5625 / 2 - 45) : 394}px)`,

              } :
              state.windowWidth > 600 ?
              {
                top: `${state.windowWidth * 0.5625 / 2 + 32}px`,
              } :
              state.windowWidth > 600 ?
              {
                top: `${state.windowWidth * 0.5625 - 80}px`,
              } :
              {
                top: `260px`,
              }
            }
          >
            <div className={`playHandlerInner`} onClick={hasPlayer} role="button" tabIndex="0" onKeyDown={hasPlayer}>
              <SvgPlay />
            </div>
          </div>} */}


        <div className="column column--right">

          {/* {featuredImage?.fluid && (
            <Image
              fluid={featuredImage.fluid}
              alt={featuredImage.alt}
              style={{ marginBottom: 50 }}
            />
          )} */}
        </div>
      </div>
    </>
  )
}
export default Start
import React, { useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import SvgBoxDeco from 'src/components/business/svgBoxDeco'

import {
  GlobalStateContext,
} from 'src/context/GlobalContextProvider'

const Technology = ({ page }) => {
  const graphqlResult = useStaticQuery(graphql`
    query BrandTechnologyPage {
      technology: wpCptBrandPage(AcfGatsbyId: {pageid: {eq: "technology"}}) {      
        acfbrandpagetechnology {
          subheaderBrandPageTechnology
          icons{
            icon{
              localFile {
                publicURL
              }
            }
            title
          }
        }
        acfbrandpageteam {
          gifBrandPageTeam {
            localFile {
              publicURL
              name
              base
              ext
            }
          }

        }
      }      
    }
  `)
  const featuredImage = {
    fluid: page.featuredImage?.node?.localFile?.childImageSharp?.fluid,
    alt: page.featuredImage?.node?.alt || ``,
  }

  const state = useContext(GlobalStateContext)

  return (
    <>

    <div>
      <div className="section-header">
        <h1>
          <span className={page.title.length > 10 ? 'heading--toLong' : page.title.length > 8 ? 'heading--long' : ''}>
            {page.title}
          </span>
        </h1>
      </div>
      <div className="box box--idea boxWithDeco">
        <div className={`boxDeco`}>
          <SvgBoxDeco />
        </div>
        <h2 className={`beforeImgTxt`}>{graphqlResult.technology.acfbrandpagetechnology.subheaderBrandPageTechnology}</h2>
        <div className={`wpContent`} dangerouslySetInnerHTML={{ __html: page.content }}></div>
        
        <div className={`iconsWrapper`}>
        {graphqlResult.technology.acfbrandpagetechnology.icons.map(item => (

          <div className={`singleIcon`} key={item.id}>
            <div className={`icon`} >
              <img src={item.icon.localFile.publicURL} />
            </div>
            <p>{item.title}</p>
          </div>
        ))}

        </div>

      </div>
    </div>

    </>
  )
}
export default Technology
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import SvgBoxDeco from 'src/components/business/svgBoxDeco'
import parse from 'html-react-parser'

import OfferIcon1 from 'src/images/benefitsIcons/icon-dlaczego-dokladnie-tak-jak-chcesz.svg'
import OfferIcon2 from 'src/images/benefitsIcons/icon-dlaczego-obsluga-z-gornej-polki.svg'
import OfferIcon3 from 'src/images/benefitsIcons/icon-dlaczego-unikatowy-produkt.svg'
import OfferIcon4 from 'src/images/benefitsIcons/icon-dlaczego-zawsze-na-czas.svg'

const Idea = ({ page }) => {
  const graphqlResult = useStaticQuery(graphql`
    query BrandIdeaPage {
      wpCptBrandPage(AcfGatsbyId: {pageid: {eq: "idea"}}) {
				acfbrandpageidea {
					idea1TitleBrandPageIdea
          idea1TextBrandPageIdea

          idea2TitleBrandPageIdea
          idea2TextBrandPageIdea

          idea3TitleBrandPageIdea
          idea3TextBrandPageIdea

          idea4TitleBrandPageIdea
          idea4TextBrandPageIdea

          subheaderBrandPageIdea
        }
      }
    }
  `)

  const idea = graphqlResult.wpCptBrandPage.acfbrandpageidea

  return (
    <div>
      <div className="section-header">
        <h1>
          <span className={page.title.length > 10 ? 'heading--toLong' : page.title.length > 8 ? 'heading--long' : ''}>
            {page.title}
          </span>
        </h1>
      </div>
      <div className="box box--idea boxWithDeco">
        <div className={`boxDeco`}>
          <SvgBoxDeco />
        </div>
        {idea.subheaderBrandPageIdea && <h2>{idea.subheaderBrandPageIdea}</h2>}
        {page.content && <div className={`wpContent`} dangerouslySetInnerHTML={{ __html: page.content }}></div>}
        <div className="idea-list">
          <div className="card">
            <div className="card_image">
              <img alt="decoration" src={OfferIcon3}></img>
            </div>
            <div>
              <h4>{idea.idea1TitleBrandPageIdea}</h4>
              {parse(idea.idea1TextBrandPageIdea || '')}
            </div>
          </div>
          <div className="card">
            <div className="card_image">
              <img alt="decoration" src={OfferIcon4}></img>
            </div>
            <div>
              <h4>{idea.idea2TitleBrandPageIdea}</h4>
              {parse(idea.idea2TextBrandPageIdea || '')}
            </div>
          </div>
          <div className="card">
            <div className="card_image">
              <img alt="decoration" src={OfferIcon2}></img>
            </div>
            <div>
              <h4>{idea.idea3TitleBrandPageIdea}</h4>
              {parse(idea.idea3TextBrandPageIdea || '')}
            </div>
          </div>
          <div className="card">
            <div className="card_image">
              <img alt="decoration" src={OfferIcon1}></img>
            </div>
            <div>
              <h4>{idea.idea4TitleBrandPageIdea}</h4>
              {parse(idea.idea4TextBrandPageIdea || '')}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Idea
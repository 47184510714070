import React from 'react'
import Image from 'gatsby-image'

const Default = ({ page, pageContext }) => {
  const featuredImage = {
    fluid: page.featuredImage?.node?.localFile?.childImageSharp?.fluid,
    alt: page.featuredImage?.node?.alt || ``,
  }
  return (
    <div className="grid">
      <div className="column column--left">
        <h1 className={`${page.title.length > 6 ? 'heading--long' : ''} mobileFixed`}>
          {page.title}
        </h1>
        <div className={`wpContent`} dangerouslySetInnerHTML={{ __html: page.content }}></div>
      </div>
      <div className="column column--right mobileFixed">
        {featuredImage?.fluid && (
          <Image
            fluid={featuredImage.fluid}
            alt={featuredImage.alt}
            title={featuredImage.title}
            description={featuredImage.description}
            style={{ marginBottom: 50 }}
          />
        )}
      </div>
    </div>
  )
}
export default Default
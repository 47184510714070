import React from 'react'

const SvgBoxDeco = () => {

  return (
    <div className={`boxDecoINT`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox={`0 20 1116.213 1087.989`}
      >
        <path
          id="Path_7165"
          d="M-.856,83.57l626.495-1.033L930.357,1167-.076,1170.525Z"
          fill="#000"
        />
      </svg>
    </div>
  )
}
export default SvgBoxDeco

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import ContactForm from 'src/components/common/contactForm'

import FbIco from 'src/images/socialIcons/facebook.js'
import InIco from 'src/images/socialIcons/instagram.js'
import LiIco from 'src/images/socialIcons/linkedin.js'
import PiIco from 'src/images/socialIcons/pinterest.js'
import YtIco from 'src/images/socialIcons/youtube.js'

const Contact = ({ page, pageContext }) => {
  const graphqlResult = useStaticQuery(graphql`
    query BrandContactPage {
      contact: wpCptBrandPage(AcfGatsbyId: {pageid: {eq: "contact"}}) {      
        acfbrandpagecontact {
          subheaderBrandPageContact
          subheader2BrandPageContact
        }
      }
      siteLanguage: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "language"}}) {
        acfgeneralinfos_layout {
          lineGenralInfosInfo
        }
      }
      generalInfo: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "footer-info-column"}}) {
        acfgeneralinfosfootercolumn_layout {
          line0GenralInfosFooterColumn
          line1GenralInfosFooterColumn
          line2GenralInfosFooterColumn
          line3GenralInfosFooterColumn
          line4GenralInfosFooterColumn
          line5GenralInfosFooterColumn
          line6GenralInfosFooterColumn
          line7GenralInfosFooterColumn
          line8GenralInfosFooterColumn
          line9GenralInfosFooterColumn
          line10GenralInfosFooterColumn
          line11GenralInfosFooterColumn
          line12GenralInfosFooterColumn
          line13GenralInfosFooterColumn
        }
      } 
      defaultPhone: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "default-phone"}}) {
        acfgeneralinfos_layout {
          lineGenralInfosInfo
          line2GenralInfosInfo
        }
      }
      thankyou: wpCptBrandPage(AcfGatsbyId: {pageid: {eq: "thank-you"}}) {
        slug
      }
    }
  `)
  const currentLang = graphqlResult.siteLanguage.acfgeneralinfos_layout.lineGenralInfosInfo

  const phoneHref = graphqlResult.defaultPhone.acfgeneralinfos_layout.lineGenralInfosInfo
  const phoneHref2 = graphqlResult.defaultPhone.acfgeneralinfos_layout.line2GenralInfosInfo
  const phoneHrefClean = phoneHref.replace(/\s+/g, '')
  const phoneHrefClean2 = phoneHref2 ? phoneHref2.replace(/\s+/g, '') : ''
  const footerInfo = graphqlResult.generalInfo.acfgeneralinfosfootercolumn_layout.line0GenralInfosFooterColumn
  const footerInfo1 = graphqlResult.generalInfo.acfgeneralinfosfootercolumn_layout.line1GenralInfosFooterColumn
  const footerInfo2 = graphqlResult.generalInfo.acfgeneralinfosfootercolumn_layout.line2GenralInfosFooterColumn
  const footerInfo3 = graphqlResult.generalInfo.acfgeneralinfosfootercolumn_layout.line3GenralInfosFooterColumn
  const footerInfo4 = graphqlResult.generalInfo.acfgeneralinfosfootercolumn_layout.line4GenralInfosFooterColumn
  const footerInfo5 = graphqlResult.generalInfo.acfgeneralinfosfootercolumn_layout.line5GenralInfosFooterColumn
  const footerInfo6 = graphqlResult.generalInfo.acfgeneralinfosfootercolumn_layout.line6GenralInfosFooterColumn
  const footerInfo7 = graphqlResult.generalInfo.acfgeneralinfosfootercolumn_layout.line7GenralInfosFooterColumn
  const footerInfo8 = graphqlResult.generalInfo.acfgeneralinfosfootercolumn_layout.line8GenralInfosFooterColumn
  const footerInfo9 = graphqlResult.generalInfo.acfgeneralinfosfootercolumn_layout.line9GenralInfosFooterColumn
  const footerInfo10 = graphqlResult.generalInfo.acfgeneralinfosfootercolumn_layout.line10GenralInfosFooterColumn
  const footerInfo11 = graphqlResult.generalInfo.acfgeneralinfosfootercolumn_layout.line11GenralInfosFooterColumn
  const footerInfo12 = graphqlResult.generalInfo.acfgeneralinfosfootercolumn_layout.line12GenralInfosFooterColumn
  const footerInfo13 = graphqlResult.generalInfo.acfgeneralinfosfootercolumn_layout.line13GenralInfosFooterColumn

  return (
    <div className="grid">
      <div className="column column--left">
        <h1 className={page.title.length > 6 ? 'heading--long' : ''}>
          {page.title}
        </h1>
        
        
        <div className={`wpContent`} dangerouslySetInnerHTML={{ __html: page.content }}></div>
        <h4>{graphqlResult.contact.acfbrandpagecontact.subheaderBrandPageContact}</h4>
        
        <div className={`socIcoWrap`}>
            <p className={`beforeImgTxt`}>{graphqlResult.contact.acfbrandpagecontact.subheader2BrandPageContact}</p>
            <a href="https://www.facebook.com/themodernforms" target="_blank" rel="noreferrer" className={`socIco`} style={{maxWidth: '44px'}}>
              <FbIco />
            </a>
            <a href="https://www.instagram.com/themodernforms/" target="_blank" rel="noreferrer" className={`socIco`} style={{maxWidth: '44px'}}>
              <InIco />
            </a>
            <a href="https://www.linkedin.com/company/modern-forms" target="_blank" rel="noreferrer" className={`socIco`} style={{maxWidth: '44px'}}>
              <LiIco />
            </a>
            <a href="https://pl.pinterest.com/themodernforms/_created/" target="_blank" rel="noreferrer" className={`socIco`} style={{maxWidth: '44px'}}>
              <PiIco />
            </a>
            <a href="https://www.youtube.com/channel/UCPmAyp3GvSFdkBWlIeHnZOQ" target="_blank" rel="noreferrer" className={`socIco`} style={{maxWidth: '44px'}}>
              <YtIco />
            </a>
        </div>

        {/* <div className={`contact-info`}> */}
          {/* <h5>{currentLang === 'de-DE' ? `Unternehmensdaten` : currentLang === 'pl-PL' ? 'Dane firmy' : `Company's data`}</h5> */}
          {/* <span>{footerInfo && footerInfo}</span> */}
          {/* <span>{footerInfo1 && footerInfo1}</span> */}
          {/* <span>{footerInfo2 && footerInfo2}</span> */}
          {/* <span>{footerInfo3 && footerInfo3}</span> */}
          {/* <span className={`account first`}><strong>{footerInfo4 && footerInfo4}</strong></span> */}
          {/* <span className={`account xx`}>{footerInfo5 && footerInfo5}</span> */}
          {/* <span>{footerInfo6 && footerInfo6}</span> */}
          {/* <span className={`account`}>{footerInfo7 && footerInfo7}</span> */}
          {/* <span className={`account xx`}>{footerInfo8 && footerInfo8}</span> */}
          {/* <span className={`account`}>{footerInfo9 && footerInfo9}</span> */}
          {/* <span className={`account xx`}>{footerInfo10 && footerInfo10}</span> */}
          {/* <span className={`account`}>{footerInfo11 && footerInfo11}</span> */}
          {/* <span className={`account xx`}>{footerInfo12 && footerInfo12}</span> */}
          {/* <span className={`account`}>{footerInfo13 && footerInfo13}</span> */}
          {/* <a href={`mailto:${footerInfo6}`} className={`cta2`}>{footerInfo6}</a> */}
          {/* <a href={`tel:${currentLang !== 'de-DE' ? phoneHrefClean : phoneHrefClean2}`} className={`cta2`}>{currentLang !== 'de-DE' ? phoneHref : phoneHref2}</a> */}
        {/* </div> */}

      </div>
      <div className="column column--right">
        <div className="contact">
          <ContactForm pageContext={pageContext} thankyou={graphqlResult.thankyou.slug} subscription={page.acffreshmailsubs} />
        </div>
      </div>
    </div>
  )
}
export default Contact
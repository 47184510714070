import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
// import Image from 'gatsby-image'
import parse from 'html-react-parser'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons'
import SvgBoxDeco from 'src/components/business/svgBoxDeco'
import FileUploadForm from 'src/components/common/fileUploadForm'

const Page = ({ pageContext, page, career, careerWp, careerTypes }) => {

  const initTypes = () => {
    // CREATE OBJECT WITH CAREER TYPES GROUPS
    const temp = {}
    careerTypes.nodes.forEach((el) => {
      el.items = []
      temp[el.slug] = el
    })
    // POPULATE EACH GROUP ITEM ARRAY WITH PROPER JOB OFFERS
    Object.keys(temp).forEach(group => {
      temp[group].items = [...career.filter(it => {
        return it.cPTTXjobOfferTypess.nodes.some(n => n.slug === temp[group].slug)
      })]      
    })
    return temp
  }
  const currentCatTypes = initTypes()

  // ********** HOOK VERSION OF CODE BLOCK ABOVE (I'm not sureif It should be written without hook (there is no browser runtime data fetching in this situations)) ****************** //
  // const initTypes = () => {
  //   // CREATE OBJECT WITH CAREER TYPES GROUPS
  //   const temp = {}
  //   careerTypes.nodes.forEach((el) => {
  //     el.items = []
  //     temp[el.slug] = el
  //   })
  //   // POPULATE EACH GROUP ITEM ARRAY WITH PROPER JOB OFFERS
  //   Object.keys(temp).forEach(group => {
  //     temp[group].items = [...career.filter(it => {
  //       return it.cPTTXjobOfferTypess.nodes.some(n => n.slug === temp[group].slug)
  //     })]      
  //   })
  //   return temp
  // }
  // const [currentCarTypes, setCurrentCarTypes] = useState(() => initTypes())

  // console.log(currentCatTypes)

  const [selectedList, setSelectedList] = useState(0)


  return (
    <>

      <div className="section-header">
        <h1>
          <span className={page.title.length > 10 ? 'heading--toLong' : page.title.length > 8 ? 'heading--long' : ''}>
            {page.title}
          </span>
        </h1>
      </div>

      <div className="box box--idea boxWithDeco">
        <div className={`boxDeco`}>
          <SvgBoxDeco />
        </div>

        <div className={`wpContent`} dangerouslySetInnerHTML={{ __html: page.content }}></div>

        <div className="career">

          {/* {!career.length && <h2 style={{textTransform:'uppercase',alignSelf:'flex-start'}}>{career.length ? careerWp.acfbrandpagecareer.ifOffersBrandPageCareer : careerWp.acfbrandpagecareer.ifNoOffersBrandPageCareer}</h2>} */}

          {Object.keys(currentCatTypes).length > 0 &&
            <>

              <div
                className={`careerListHead`}                
              >
              {Object.keys(currentCatTypes).map((list, i) => {
                return(
                  <button
                    key={i}
                    className={`cta button ${selectedList === i ? 'active' : ''}`}
                    onClick={() => setSelectedList(i)}
                  >
                    {currentCatTypes[Object.keys(currentCatTypes)[i]].name}
                  </button>
                )})            
              }
              </div>

              {currentCatTypes[Object.keys(currentCatTypes)[selectedList]].items.length > 0 ?
                <ul className={`careerList`}>
                  {currentCatTypes[Object.keys(currentCatTypes)[selectedList]].items.map((item, key) => {
                    return(
                      <li key={key} className={`careerListItem`}>

                        <AniLink 
                          swipe
                          duration={1.6}
                          direction="left"
                          entryOffset={400}
                          to={`${pageContext.pagePath}${item.slug}`}
                          data-hover={item.title}
                          // className={`cta2 cta2object`}
                        >
                          <span className={`absoluteTitle`}>
                            <h3>
                              {item.title}
                            </h3>
                            <h3 className={`button cta`}>
                              {careerWp.acfbrandpagecareer.singleOfferLabelBrandPageCareer}
                            </h3>
                          </span>
                          {/* <Image 
                            fluid={item.featuredImage.node.localFile.childImageSharp.fluid} 
                            style={{maxWidth:'610px'}} 
                            alt={item.featuredImage.node.altText}
                            title={item.featuredImage.node.title}
                            description={item.featuredImage.node.description}
                          /> */}
                        </AniLink>

                      </li>
                    )
                  })}

                </ul>
                :
                <h2 className={`careerListEmpty`}>{careerWp.acfbrandpagecareer.ifNoOffersBrandPageCareer}</h2>
              }

            </>
          }

        </div>
      
      </div>

      {/* <div className={``}>
        <div className={`column column--left`}>          
          
        </div>
      </div> */}

      
    </>
  )
}

const Subpage = ({ page, subpage, pageContext, career, email, careerWp, thankyou }) => {

  // const featuredImage = {
  //   fluid: subpage.featuredImage?.node?.localFile?.childImageSharp?.fluid,
  //   alt: subpage.featuredImage?.node?.altText || ``,
  // }
  let currentSubpageIndex;
  const getSubpageUri = ({ nextPage }) => {
    currentSubpageIndex = career.findIndex(
      x => x.slug === subpage.slug,
    )
    if (
      currentSubpageIndex < 0 ||
      (nextPage && currentSubpageIndex >= career.length - 1) ||
      (!nextPage && currentSubpageIndex <= 0)
    )
      return false
    return `${pageContext.sitePath}${pageContext.pageSlug}/${
      career[currentSubpageIndex + (nextPage ? 1 : -1)].slug
    }`
  }
  const nextPage = getSubpageUri({ nextPage: true })
  const prevPage = getSubpageUri({ nextPage: false })

  // console.log(subpage.acffreshmailsubs)
  // console.log(subpage)

  return (
    <div className={`careerSingle`}>
      <h1 className={subpage.title.length > 6 ? 'heading--long' : ''}>
        {subpage.title}
      </h1>
      {/* <div className={`careerSubnavWrapEXT`}>
        <div className={`careerSubnavWrap`}>
          {prevPage ?
          <AniLink 
            swipe
            duration={1.6}
            direction="right"
            entryOffset={400}
            className={`careerSubnav careerSubnavPrev cta2`} 
            to={prevPage}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
            {careerWp.acfbrandpagecareer.paginationPrevBrandPageCareer}
          </AniLink> : ''}
          {nextPage ?
          <AniLink 
            swipe
            duration={1.6}
            direction="left"
            entryOffset={400}
            className={`careerSubnav careerSubnavNext cta2`} 
            to={nextPage}
          >
            {careerWp.acfbrandpagecareer.paginationNextBrandPageCareer}
            <FontAwesomeIcon icon={faChevronRight} />
          </AniLink> : ''}
        </div>
        <AniLink 
          swipe
          duration={1.6}
          direction="left"
          entryOffset={400}
          className={`careerSubnav careerSubnavBack cta2`} 
          to={pageContext.sitePath+pageContext.pageSlug}
        >
          {careerWp.acfbrandpagecareer.gobackBrandPageCareer}
          <FontAwesomeIcon icon={faChevronUp} />
        </AniLink>
      </div> */}
      {/* {featuredImage?.fluid && (
        <Image
          fluid={featuredImage.fluid}
          alt={featuredImage.alt}
          style={{ marginBottom: 50, maxWidth:'610px' }}
        />
      )} */}

      {parse(subpage.content || '')}

      {/* v1 */}
      {/* <a href={`mailto:${email}?subject=Kariera`} className="cta row row--primary button" style={{textTransform:'none'}}>{email}</a> */}

      {/* v2 */}
      {/* <a href="mailto:praca@modernforms.pl?subject=Kariera" class="cta row row--primary button" style={{textTransform: 'none'}}>praca@modernforms.pl</a> */}


      <div className={`contact fileUploadForm`} >
        <FileUploadForm pageContext={pageContext} thankyou={thankyou.slug} subscription={subpage.acffreshmailsubs} />
      </div>


      <div className={`careerSubnavWrapEXT`}>
      <div className={`careerSubnavWrap`}>
        {prevPage ?
          <AniLink 
            swipe
            duration={1.6}
            direction="right"
            entryOffset={400}
            className={`careerSubnav careerSubnavPrev cta2`} 
            to={prevPage}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
            <FontAwesomeIcon icon={faChevronLeft} />
            {careerWp.acfbrandpagecareer.paginationPrevBrandPageCareer}
          </AniLink> : ''}
        {nextPage ?
          <AniLink 
            swipe
            duration={1.6}
            direction="left"
            entryOffset={400}
            className={`careerSubnav careerSubnavNext cta2`} 
            to={nextPage}
          >
            {careerWp.acfbrandpagecareer.paginationNextBrandPageCareer}
            <FontAwesomeIcon icon={faChevronRight} />
            <FontAwesomeIcon icon={faChevronRight} />
          </AniLink> : ''}
          </div>

          <AniLink 
            swipe
            duration={1.6}
            direction="left"
            entryOffset={400}
            className={`careerSubnav careerSubnavBack cta2`} 
            to={pageContext.sitePath+pageContext.pageSlug}
          >
            {careerWp.acfbrandpagecareer.gobackBrandPageCareer}
            <span className='doubleUp' style={{display:'inline-flex',flexDirection:'column',width:'8px'}}>
              <FontAwesomeIcon icon={faChevronUp} />
              <FontAwesomeIcon icon={faChevronUp} />
            </span>
          </AniLink>
      </div>
    </div>
  )
}

const Career = (props) => {
  const pagePosts = useStaticQuery(graphql`
    query BrandCareerPosts {
      career: wpCptBrandPage(AcfGatsbyId: {pageid: {eq: "career"}}) {      
        acfbrandpagecareer {
          ifOffersBrandPageCareer
          ifNoOffersBrandPageCareer
          paginationPrevBrandPageCareer
          paginationNextBrandPageCareer
          gobackBrandPageCareer
          singleOfferLabelBrandPageCareer
        }
      }
      careerTypes: allWpCpttXjobOfferTypes {
        nodes {
          slug
          name
        }
      }
      thankyou: wpCptBrandPage(AcfGatsbyId: {pageid: {eq: "thank-you"}}) {
        slug
      }
    }
  `)
  const childProps = {
    ...props,
    career: props.page.wpChildren.nodes,
    careerWp: pagePosts.career,
    careerTypes: pagePosts.careerTypes,
    thankyou: pagePosts.thankyou
  }
  return (!props.subpage) ? Page(childProps) : Subpage(childProps)
}
export default Career
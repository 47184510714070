import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'

const Products = ({ page }) => {

  const pageSlides = useStaticQuery(graphql`
    query BrandProductsSlides {
      allWpCptBrandProduct {
        edges {
          slide: node {
            id
            featuredImage {
              node {
                altText
                description
                title
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1000, quality: 60) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            acfgenralinfosproductcatalogue {
              katalog {
                localFile{
                  publicURL
                  name
                  ext
                  base
                }
              }
              nazwaKatalogu
            }
          }
        }
      }
      products: wpCptBrandPage(AcfGatsbyId: {pageid: {eq: "products"}}) {      
        acfbrandpageproducts {
          subheaderBrandPageProducts
        }
      }
    }
  `)
  const slides = pageSlides.allWpCptBrandProduct.edges

  return (
    <div className="grid">
      <div className="column column--left" style={{ width: '100%' }}>
        <h1 className={page.title.length > 8 ? 'heading--long' : ''}>
          {page.title}
        </h1>
        <h2 className={`beforeImgTxt`}>{pageSlides.products.acfbrandpageproducts.subheaderBrandPageProducts}</h2>

        <div className="products" style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
            {[...slides].reverse().map(({ slide }) => {
              const featuredImage = {
                fluid: slide.featuredImage?.node?.localFile?.childImageSharp?.fluid,
                alt: slide.featuredImage?.node?.alt || ``,
              }
              const catalogue = {
                href: slide.acfgenralinfosproductcatalogue.katalog.localFile.publicURL,
                slug: slide.acfgenralinfosproductcatalogue.katalog.localFile.name,
              }
              const catalogueName = slide.acfgenralinfosproductcatalogue.nazwaKatalogu
              
              return (
                featuredImage?.fluid && (
                  <div className={'item'}>
                    <a href={catalogue.href} key={slide.id} style={{ display: 'block' }} target="_blank">
                      <Image 
                        fluid={featuredImage.fluid} 
                        alt={featuredImage.alt} 
                        title={featuredImage.title}
                        description={featuredImage.description}
                      />
                      <h3>{catalogueName}</h3>
                    </a>
                  </div>
                )
              )
            })}
        </div>

        <div className={`wpContent`} dangerouslySetInnerHTML={{ __html: page.content }}></div>

      </div>

    </div>
  )
}
export default Products
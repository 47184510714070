import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { navigate } from "gatsby"

const ThankYou = ({ page, location }) => {
  const graphqlResult = useStaticQuery(graphql`
    query BrandThankYouPage {
      thankyou: wpCptBrandPage(AcfGatsbyId: {pageid: {eq: "thank-you"}}) {
        acfbrandpagethankyou {
          subheaderBrandPageThankYou
          subheader2BrandPageThankYou
          ctaTextBrandPageThankYou
          ctaLinkBrandPageThankYou
          redirectLabelBrandPageThankYou
          redirectTimeBrandPageThankYou
        }
      }
    }
  `)

  // useEffect(() => {

  //   if(typeof window !== 'undefined') {

  //     if(typeof window.dataLayer !== 'undefined') {
        
  //       window.dataLayer.push({
  //         // 'event' : 'contactFormSubmission-Brand',
  //         'event' : 'pageview',
  //         'pageType' : 'ThankYouPage-Brand',
          
  //       });
        
  //       console.log('DATALAYER PUSH')
  //     }
  //   }
  // }, [])

  const redirect = location.search !== '' ? location.search.slice(1) : graphqlResult.thankyou.acfbrandpagethankyou.ctaLinkBrandPageThankYou === null ? '/' : graphqlResult.thankyou.acfbrandpagethankyou.ctaLinkBrandPageThankYou

  const[count, setCount] = useState(graphqlResult.thankyou.acfbrandpagethankyou.redirectTimeBrandPageThankYou)
  useEffect(() => {
    const timer = setTimeout(() => {
      setCount(count - 1)
    }, 1000);
    return () => clearTimeout(timer);
  }, [count])

  useEffect(() => {
    if(count === 0) {
      navigate(redirect)
    }
  }, [count])

  return (
    <div className="grid">
      <div className="column column--left">
        <h1 className={`${page.title.length > 8 ? 'heading--long' : ''} mobileFixed`}>
          {page.title}
        </h1>
        <h2>{graphqlResult.thankyou.acfbrandpagethankyou.subheaderBrandPageThankYou}</h2>
        <span className={`beforeImgTxt`}>{graphqlResult.thankyou.acfbrandpagethankyou.subheader2BrandPageThankYou}</span>
        <div className={`wpContent`} dangerouslySetInnerHTML={{ __html: page.content }}></div>
        <div className="products-buttons">
          {graphqlResult.thankyou.acfbrandpagethankyou.ctaLinkBrandPageThankYou &&
          <>
            <AniLink
              // to={graphqlResult.thankyou.acfbrandpagethankyou.ctaLinkBrandPageThankYou}
              to={redirect}
              duration={1.6}
              swipe
              direction="right"
              entryOffset={400}
              className="button button--download cta"
              data-hover={graphqlResult.thankyou.acfbrandpagethankyou.ctaTextBrandPageThankYou}
            >
              {graphqlResult.thankyou.acfbrandpagethankyou.ctaTextBrandPageThankYou}
            </AniLink>
            <span style={{color:'white',fontSize:'16px',marginTop:'28px'}}>
              {graphqlResult.thankyou.acfbrandpagethankyou.redirectLabelBrandPageThankYou}
              {count}
            </span>
          </>
          }
        </div>
      </div>
    </div>
  )
}
export default ThankYou
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import Image from 'gatsby-image'
import parse from 'html-react-parser'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import SvgBoxDeco from 'src/components/business/svgBoxDeco'

const Page = ({ page, pageContext }) => {
  const graphqlResult = useStaticQuery(graphql`
    query BrandOfferPage {
      offer: wpCptBrandPage(AcfGatsbyId: {pageid: {eq: "offer"}}) {      
        acfbrandpageoffer {
          subheaderBrandPageOffer
        }
      }
    }
  `)
  const offer = page.wpChildren.nodes

  return (
    <>
    <div>
      <div className="section-header">
        <h1 className={`${page.title.length > 6 ? 'heading--long' : ''} mobileFixed`}>
          {page.title}
        </h1>
      </div>
      <div className="box box--offer boxWithDeco">
        <div className={`boxDeco`}>
          <SvgBoxDeco />
        </div>
        <h2 className={`beforeImgTxt`}>{graphqlResult.offer.acfbrandpageoffer.subheaderBrandPageOffer}</h2>
        <div className={`wpContent`} dangerouslySetInnerHTML={{ __html: page.content }}></div>
        <div className="offerWrapper">
          <div className="offer">
            {[...offer].reverse().map((item) => {
              const featuredImage = {
                fluid: item.featuredImage?.node?.localFile?.childImageSharp?.fluid,
                alt: item.featuredImage?.node?.alt || ``,
              }
              return (
                <div className="item cta2object" key={item.id}>
                  <AniLink 
                    className={`cta2`}
                    swipe
                    direction="left"
                    duration={1.6}
                    entryOffset={400}
                    to={`${pageContext.pagePath}${item.slug}`}
                  >
                    <h3 style={{textDecoration:'none'}}>{item.title}</h3>
                    {featuredImage?.fluid && (
                      <Image 
                        fluid={featuredImage.fluid} 
                        alt={featuredImage.alt}
                        title={featuredImage.title}
                        description={featuredImage.description} 
                        style={{backgroundColor:'rgb(22, 22, 26)'}} 
                      />
                    )}
                  </AniLink>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>



    <div className="grid">
      <div className="column column--left">
        

      </div>
      <div className="column column--right mobileFixed">

      </div>
    </div>

    </>
  )
}

const Subpage = ({ page, subpage, pageContext }) => {
  return (
    <div className="modal-wrapper">
      <AniLink 
        swipe
        direction="right"
        duration={1.6}
        entryOffset={400}
        className={`backLink`} 
        to={pageContext.sitePath + pageContext.pageSlug}
        style={{maxWidth:'20px'}}
      >
        <FontAwesomeIcon icon={faTimes}/>
      </AniLink>
      <div className="modal">
        <div className="grid">
          <div className="modal-column modal-column--left">
            <Image 
              fluid={subpage.featuredImage.node.localFile.childImageSharp.fluid} 
              alt={subpage.featuredImage.node.alt}
              title={subpage.featuredImage.node.title}
              description={subpage.featuredImage.node.description}
            />

            {subpage.acfbrandpageoffer.ctaLinkBrandPageOffer && <div style={{display:'flex',justifyContent:'center',alignItems:'center',margin:'40px auto 0 auto'}}>
              <a 
                href={subpage.acfbrandpageoffer.ctaLinkBrandPageOffer} 
                className="cta row row--primary button" 
                style={{textTransform:'none'}}
                // target="_blank"
                // rel="noreferrer"
              >
                {subpage.acfbrandpageoffer.ctaLabelBrandPageOffer}
              </a>
            </div>}

          </div>
          <div className="modal-column modal-column--right">
            <h1 className={`${page.title.length > 6 ? 'heading--long' : ''} mobileFixed`}>{subpage.title}</h1>
            <h2 className={`beforeImgTxt`}>
              {subpage.acfbrandpageoffer.subheaderBrandPageOffer && subpage.acfbrandpageoffer.subheaderBrandPageOffer}
            </h2>
            {/* <div className={`wpContent`} dangerouslySetInnerHTML={{ __html: page.content }}></div> */}
            {parse(subpage.content || '')}
          </div>
        </div>
      </div>
    </div>
  )
}
const Offer = (props) => {
  return props.subpage ? Subpage(props) : Page(props)
}
export default Offer